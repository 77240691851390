import CostCenterRoutes from "@/router/admin/cost-center";
import UserRoutes from "@/router/admin/user";
import DepartmentRoutes from "@/router/admin/department";
import SupplierRoutes from "@/router/admin/supplier";
import ClientRoutes from "@/router/admin/client";
import ProductRoutes from "@/router/admin/product";
import ProductGroupRoutes from "@/router/admin/product-group";
import LedgerAccountRoutes from "@/router/admin/ledger-account";
import PostingKeyRoutes from "@/router/admin/posting-key";
import PermissionRoutes from "@/router/admin/permission";
import ProfitCenterRoutes from "@/router/admin/profit-center";
import TaxGroupRoutes from "@/router/admin/tax-group";
import TaxItemGroupRoutes from "@/router/admin/tax-item-group";
import BranchRoutes from "@/router/admin/branch";
import EmployeeRoutes from "@/router/admin/employee";
import TaskGroupRoutes from "@/router/admin/task-group";

const AdminRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/client/list.vue"),
    },
    {
        path: 'user',
        name: 'user',
        component: () => import("@/components/admin/user/index.vue"),
        children: UserRoutes
    },
    {
        path: 'cost-center',
        name: 'cost-center',
        component: () => import("@/components/admin/cost-center/index.vue"),
        children: CostCenterRoutes
    },
    {
        path: 'department',
        name: 'department',
        component: () => import("@/components/admin/department/index.vue"),
        children: DepartmentRoutes
    },
    {
        path: 'supplier',
        name: 'supplier',
        component: () => import("@/components/admin/supplier/index.vue"),
        children: SupplierRoutes
    },
    {
        path: 'client',
        name: 'client',
        component: () => import("@/components/admin/client/index.vue"),
        children: ClientRoutes
    },
    {
        path: 'product',
        name: 'product',
        component: () => import("@/components/admin/product/index.vue"),
        children: ProductRoutes
    },
    {
        path: 'group-product',
        name: 'product-group',
        component: () => import("@/components/admin/product-group/index.vue"),
        children: ProductGroupRoutes
    },
    {
        path: 'configuration',
        name: 'configuration',
        component: () => import("@/components/admin/configuration/form.vue"),
    },
    {
        path: 'invoice',
        name: 'invoice-admin',
        component: () => import("@/components/invoice/index.vue"),
    },
    {
        path: 'ledger-account',
        name: 'ledger-account',
        component: () => import("@/components/admin/ledger-account/index.vue"),
        children: LedgerAccountRoutes
    },
    {
        path: 'posting-key',
        name: 'posting-key',
        component: () => import("@/components/admin/posting-key/index.vue"),
        children: PostingKeyRoutes
    },
    {
        path: 'profit-center',
        name: 'profit-center',
        component: () => import("@/components/admin/profit-center/index.vue"),
        children: ProfitCenterRoutes
    },
    {
        path: 'branch',
        name: 'branch',
        component: () => import("@/components/admin/branch/index.vue"),
        children: BranchRoutes
    },
    {
        path: 'employee',
        name: 'employee',
        component: () => import("@/components/admin/employee/index.vue"),
        children: EmployeeRoutes
    },
    {
        path: 'tax-group',
        name: 'tax-group',
        component: () => import("@/components/admin/tax-group/index.vue"),
        children: TaxGroupRoutes
    },
    {
        path: 'tax-item-group',
        name: 'tax-item-group',
        component: () => import("@/components/admin/tax-item-group/index.vue"),
        children: TaxItemGroupRoutes
    },
    {
        path: 'permission',
        name: 'permission',
        component: () => import("@/components/admin/permission/index.vue"),
        children: PermissionRoutes
    },
    {
        path: 'task-group',
        name: 'task-group',
        component: () => import("@/components/admin/task-group/index.vue"),
        children: TaskGroupRoutes
    },
];
export default AdminRoutes