const BranchRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/branch/list.vue")
    },
    {
        path: 'list',
        name: 'list-branch',
        component: () => import("@/components/admin/branch/list.vue")
    },
    {
        path: 'form',
        name: 'form-branch',
        component: () => import("@/components/admin/branch/form.vue")
    }
];
export default BranchRoutes