const EmployeeRoutes = [
    {
        path: '',
        component: () => import("@/components/admin/employee/list.vue")
    },
    {
        path: 'list',
        name: 'list-employee',
        component: () => import("@/components/admin/employee/list.vue")
    },
    {
        path: 'form',
        name: 'form-employee',
        component: () => import("@/components/admin/employee/form.vue")
    }
];
export default EmployeeRoutes